










































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { api } from '@/api'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  data() {
    return {
      message: null,
    }
  },
  async created () {
    logger.debug('c/Top.created')
    const companyNo = this.$route.params.companyNo
    this.kstate.company = null
    if (!this.kstate.company) {
      const res = await this.$store.dispatch('company/' + types.COMPANY_GET, companyNo)
    }
    const res = await this.$store.dispatch('company/' + types.GROUP_LIST, companyNo)
  },
  methods: {
    add() {
      const c = this.kstate.company
      if (c.ngroups >= c.max_groups) {
        this.message = '追加可能なグループ数が超過しました。'
        this.showMessage()
        return
      }
      const companyNo = this.$route.params.companyNo
      this.$router.push(`/g/${companyNo}/new`)
    },
    confirmed() {
      this.showMessage(false)
    },
    async download_user_xlsx() {
      const companyNo = this.$route.params.companyNo
      const params = {
        headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        responseType: 'blob'
      }
      const res = await api.get(`/companies/${companyNo}/users`, params)
      if (res.status === 200) {
        const c = this.kstate.company
        const url = URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.download = `CX4DX_${c.name}様アカウント情報.xlsx`
        link.click()
        URL.revokeObjectURL(url)
      } else {
        this.message = this.cstate.error
        this.showMessage()
      }
    }
  }
})

