































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import MemberMixin from '@/mixins/member'
import C4dCol from '@/components/C4dCol.vue'
import C4dImg from '@/components/C4dImg.vue'

export default Vue.extend({
  mixins: [
    MemberMixin
  ],
  components: {
    C4dCol,
    C4dImg,
  },
  props: {
    chapter: Object,
    thick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startLabel: '学習を始める'
    }
  },
  mounted () {
    const ch = this.chapter.chapter
    const p = this.mstate.p.progress[ch - 1] || {}
    logger.debug(`C4dChapterCard.mounted chapter:${ch} progress:${p.section}`)
    if (p.section == 'complete') {
      this.startLabel = '復習する'
    }
  }
})

