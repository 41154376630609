
import Vue from 'vue'

const required = (label, action) => {
  if (label) {
    label += 'を'
  } else {
    label = ''
  }
  if (!action) {
    action = '入力'
  }
  // return v => !!v || `${label}${action}してください`
  return (v) => {
    console.log(`required ${label} v=${v}`)
    return !!v || v === 0 || `${label}${action}してください。`
  }
}

const isValidPassword = (v) => {
  return /^[A-Za-z0-9!"#$%&'()=\-~^|\\{[}\]`@*:+;?\/_>.<,]{8,50}$/.test(v) // eslint-disable-line
}

const isPositiveNumber = (v) => {
  return parseInt(v) > 0
}

const isFile= (v) => {
  if (!v) {
    return true
  }
  if (v.type.indexOf('image/') == 0) {
    return true
  }
  return false
}

const isFileSizeOK = (v) => {
  if (!v) {
    return true
  }
  return v.size < 2 * 1024 * 1024
}

export default Vue.extend({
  data () {
    return {
      valid: false,
      rules: {
        required: v => !!v || '入力してください',
        name: [
          required('氏名'),
        ],
        email: [
          required('メールアドレス'),
          v => /.+@.+\..+/.test(v) || 'メールアドレスが正しく入力されていることをご確認ください。'
        ],
        emailConfirm: [
          required('メールアドレス（確認）'),
          v => v === this.form.email || 'メールアドレスとメールアドレス（確認）が一致しません。'
        ],
        passwordLogin: [
          required('パスワード')
        ],
        password: [
          required('パスワード'),
          v => isValidPassword(v) || 'パスワードの形式が違います。再度、ご入力をお願いします。（半角英数字、文字数は8〜50文字）'
        ],
        passwordConfirm: [
          required('パスワード（確認）'),
          v => v === this.form.password || 'パスワードとパスワード（確認）が一致しません。'
        ],
        positiveNumber: [
          required(''),
          v => isPositiveNumber(v) || '正しく入力して下さい'
        ],
        imageFile: [
          v => isFile(v) || '画像ファイルを選択してください',
          v => isFileSizeOK(v) || 'ファイルサイズが大きすぎます。2MB以下のファイルをアップロードしてください。',
        ],
        file: [
          v => !!v || ''
        ],
      }
    }
  }
})
