












































































































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import MemberMixin from '@/mixins/member'
import C4dImg from '@/components/C4dImg.vue'
import C4dUser from '@/components/C4dUser.vue'
import C4dHeading from '@/components/C4dHeading.vue'

export default Vue.extend({
  mixins: [
    MemberMixin
  ],
  components: {
    C4dImg,
    C4dUser,
    C4dHeading,
  },
  props: {
    progress: Object,
  },
  data() {
    return {
      detailOpen: false,
      chapter: this.progress.c,
    }
  },
  computed: {
    maxUser: function () {
      const v = this.mobile ? 5 : 10
      logger.debug(`maxUser => ${v}`)
      return v
    }
  },
  methods: {
    click() {
      this.detailOpen = true
    }
  }
})

