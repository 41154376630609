





























































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  components: {
  },
  data() {
    return {
      done: false,
      show: false,
      message: null,
      form: {
        email: '',
      }
    }
  },
  methods: {
    cancel() {
      this.showConfirm(false)
    },
    async action() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.showConfirm()
    },
    async doAction() {
      this.showConfirm(false)
      const res = await this.$store.dispatch('common/' + types.PWRESET_S1, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.done = true
        this.message = 'パスワード再設定メールを送信しました。'
      }
      this.showMessage()
    },
    confirmed() {
      this.showMessage(false)
      if (this.done) {
        this.$router.replace('/')
      }
    }
  }
})

