











































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  async created () {
    console.log('a/Top.created')
    const res = await this.$store.dispatch('admin/' + types.COMPANY_LIST)
  },
})

