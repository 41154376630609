
















































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminMixin from '@/mixins/admin'
import C4dBtn from '@/components/C4dBtn.vue'

export default Vue.extend({
  mixins: [
    AdminMixin,
  ],
  components: {
    C4dBtn,
  },
  props: {
    member: Object,
  },
  data() {
    return {
      url: ''
    }
  },
  created () {
    logger.debug('C4dGroupCard.created')
    const origin = location.origin
  },
  methods: {
    detail() {
      this.$store.commit('member/' + types.MEMBER_SET, this.member)
      this.$router.push(`/m/${this.member.id}/settings`)
    }
  }
})

