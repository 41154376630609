import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'

export default Vue.extend({
  components: {
  },
  data() {
    return {
      cstate: this.$store.state.common,
      mstate: this.$store.state.member,
      gstate: this.$store.state.group,
      kstate: this.$store.state.company,
      astate: this.$store.state.admin,
      showTos_: false,
      //showTos_: true,
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  created () {
    //console.log('CommonMixin.created')
  },
  methods: {
    isAuthRequired() {
      const path = this.$route.path
      if (path.indexOf('/m/') == 0) {
        return true
      }
      if (path.indexOf('/g/') == 0) {
        return true
      }
      if (path.indexOf('/c/') == 0) {
        return true
      }
      if (path.indexOf('/a/') == 0) {
        return true
      }
      return false
    },
    showTos() {
      this.showTos_ = true
    },
  }
})

