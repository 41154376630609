





























































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  data() {
    return {
      message: null,
    }
  },
  async created () {
    console.log('g/Top.created')
    const companyNo = this.$route.params.companyNo
    this.kstate.company = null
    this.gstate.group = null
    if (!this.kstate.company) {
      const res = await this.$store.dispatch('company/' + types.COMPANY_GET, companyNo)
    }
    const groupId = this.$route.params.groupId
    if (!this.gstate.group) {
      const res = await this.$store.dispatch('group/' + types.GROUP_GET, groupId)
    }
    const res = await this.$store.dispatch('group/' + types.MEMBER_LIST, groupId)
  },
  methods: {
    add() {
      const companyNo = this.$route.params.companyNo
      this.$router.push(`/g/${companyNo}/new`)
    },
    async invite() {
      const c = this.kstate.company
      if (c.nusers_total >= c.max_users) {
        this.message = '追加可能なアカウント数が超過しました。'
        this.showMessage()
        return
      }

      const params = {
        group_id: this.$route.params.groupId,
        role: consts.ROLE_USER
      }
      const res = await this.$store.dispatch('common/' + types.SIGNUP_ISSUE, params)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.message = `ユーザーURLを発行しました<br>（コピーしてご案内ください）<br>${res.data.url}`
      }
      this.showMessage()
    },
    confirmed() {
      logger.debug('confirmed')
      this.showMessage(false)
    }
  }
})

