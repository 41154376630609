
















import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import C4dImg from '@/components/C4dImg.vue'

export default Vue.extend({
  mixins: [
  ],
  components: {
    C4dImg,
  },
  props: {
    icon: String,
    title: [String, Number],
    font: {
      type: String,
      default: 'f18'
    },
    color: {
      type: String,
      default: 'primary2'
    }
  },
  data() {
    return {
      isMe: false,
      name: '',
      url: ''
    }
  },
  created () {
    logger.debug('C4dUser.created')
  }
})

