<template>
  <v-btn
    v-bind="$attrs"
    rounded
    :color="color"
    @click="clicked"
    class="c4d-btn font-button"
    :class="`c4d-btn-${type}`"
    xclass="c4d-btn font-button white--text"
    >
    <slot />
  </v-btn>
</template>

<style lang="scss" scoped>
// https://github.com/vuejs/vue-cli/issues/5188
/* Cannot have an empty style scoped else app.scss is not imported */
.c4d-btn {
  color: white;
  &.c4d-btn-normal3 {
    color: $color-primary;
  }
}
</style>

<script>

export default {
  props: {
    type: {
      // normal | cancel | back
      type: String,
      default: 'normal'
    },
    back: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      color: this.$attrs.color
    }
  },
  created () {
    console.log('C4dButton.created attrs=', this.$attrs)

    switch (this.type) {
      case 'normal':
        this.color = 'color1'
        break

      case 'normal3':
        this.color = 'color3'
        break

      case 'cancel':
        this.color = 'accent'
        break
    }
  },
  methods: {
    clicked () {
      /*
      if (this.to) {
        this.$router.push(this.to, () => {})
        return
      }
      */
      this.$emit('click')
      if (this.back) {
        this.$router.back()
      }
    }
  }
}
</script>
