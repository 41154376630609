import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Top from '@/views/Top.vue'
import About from '@/views/About.vue'
import Base from '@/views/Base.vue'
import MemberBase from '@/views/m/Base.vue'
import Login from '@/views/Login.vue'
import Account from '@/views/Account.vue'
import Signup from '@/views/Signup.vue'
import Verify from '@/views/Verify.vue'
import Update from '@/views/Update.vue'
import CompanyLogin from '@/views/CompanyLogin.vue'
import Pwreset1 from '@/views/Pwreset1.vue'
import Pwreset2 from '@/views/Pwreset2.vue'
import CompanyPwreset1 from '@/views/CompanyPwreset1.vue'
import CompanyPwreset2 from '@/views/CompanyPwreset2.vue'

import { member } from './member'
import { group } from './group'
import { company } from './company'
import { admin } from './admin'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'top',
    component: Top,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/company/:company_no/login',
    name: 'login',
    component: CompanyLogin,
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
  },
  {
    path: '/verify',
    name: 'verify',
    component: Verify,
  },
  {
    path: '/update',
    name: 'update',
    component: Update,
  },
  {
    path: '/pwreset/1',
    name: 'pwreset-1',
    component: Pwreset1,
  },
  {
    path: '/pwreset/2',
    name: 'pwreset-2',
    component: Pwreset2,
  },
  {
    path: '/company/:company_no/pwreset/1',
    name: 'company-pwreset-1',
    component: CompanyPwreset1,
  },
  {
    path: '/company/pwreset/2',
    name: 'company-pwreset-2',
    component: CompanyPwreset2,
  },
  {
    path: '/m',
    component: MemberBase,
    children: member,
  },
  {
    path: '/g',
    component: Base,
    children: group,
  },
  {
    path: '/c',
    component: Base,
    children: company,
  },
  {
    path: '/a',
    component: Base,
    children: admin,
  },
]

const router = new VueRouter({
  routes
})

router.afterEach((to, from) => {
  console.log('router.afterEach', to)
  scrollTo(0, 0)
})

export default router
