





































































































































































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import MemberMixin from '@/mixins/member'
import C4dImg from '@/components/C4dImg.vue'
import C4dHeading from '@/components/C4dHeading.vue'

export default Vue.extend({
  mixins: [
    MemberMixin
  ],
  components: {
    C4dImg,
    C4dHeading,
  },
  props: {
  },
  data() {
    return {
      topPathRegexp: new RegExp('^/(a|c/\\d+|g/\\d+/\\d+)$'),
      companySettingsRegexp: new RegExp('^/c/\\d+/edit$'),
      menuItemsUser: [
        {
          title: 'マイページ',
          icon: 'mdi-pencil-box',
          path: '/m/mypage',
        },
        {
          title: 'チーム共有',
          icon: 'mdi-account-group',
          path: '/m/team'
        },
        {
          title: 'ガイダンス',
          icon: 'mdi-book-open-page-variant-outline',
          click: () => {
            window.open('/guide.pdf', '_blank')
          }
        },
        {
          title: '設定',
          icon: 'mdi-cog',
          path: '/m/settings'
        },
      ],
      menuItemsAdmin: [
        {
          title: 'トップページ',
          icon: 'mdi-pencil-box',
          path: '/',
          isCurrent: (that) => {
            return that.topPathRegexp.test(that.$route.path)
          }
        },
        {
          title: '設定',
          icon: 'mdi-cog',
          click: this.clickSettings,
          isCurrent: (that) => {
            const user = this.cstate.p.user
            if (user.role == consts.ROLE_COMPANY) {
              return that.companySettingsRegexp.test(that.$route.path)
            } else {
              return that.$route.path == '/m/settings'
            }
          }
        },
      ],
      chapterMenuItems: [
        {
          title: '学習概要',
          section: consts.SCT_SUMMARY,
          rate: 0,
        },
        {
          title: 'レクチャー動画',
          section: consts.SCT_VIDEO,
          rate: 1,
        },
        {
          title: 'レクチャー内容のポイント',
          section: consts.SCT_POINT,
          rate: 6,
        },
        {
          title: '学習編クイズ',
          section: consts.SCT_QUIZ,
          rate: 8,
        },
        {
          title: '学習編クイズ解答',
          section: consts.SCT_QUIZ_EXP,
          rate: 10,
        },
      ],
      headings: {
        1: { icon: null, title: '運営者向け画面' },
        2: { icon: 'icon_company.svg', title: '企業管理者向け画面' },
        3: { icon: 'icon_group.svg', title: 'グループ管理者向け画面' },
      }
    }
  },
  computed: {
    _menuItems() {
      console.log('computed _menuItems')
      let menuItems
      if (this.cstate.p.user.role === consts.ROLE_USER) {
        menuItems = this.menuItemsUser
      } else {
        menuItems = this.menuItemsAdmin
      }
      console.log('current path=', this.$route.path)
      let items = menuItems.map((i) => {
        if (i.isCurrent) {
          i.current = i.isCurrent(this)
        } else {
          i.current = this.$route.path == i.path
        }
        logger.debug('i=', i)
        return i
      })
      return items
    },
    _chapterMenuItems() {
      console.log('computed _chapterMenuItems')
      const chno = this.mstate.p.currChapterNo
      const p = this.mstate.p.progress[chno - 1]
      let rate = p ? p.rate : 0
      logger.debug('rate=', rate)
      const section = this.getSection()
      let items = this.chapterMenuItems.map((i) => {
        if (consts.PROGRESS_FREE) {
          i.disabled = false
        } else {
          i.disabled = rate < i.rate
        }
        i.current = i.section == section
        logger.debug('i=', i)
        return i
      })
      return items
    },
    heading() {
      let h = null
      const user = this.cstate.p.user
      if (user) {
        h = this.headings[user.role]
      }
      return h
    }
  },
  methods: {
    itemClicked(item) {
      logger.debug('clicked', item)
      if (item.path) {
        this.$router.push(item.path, () => {
          //
        })
      } else if (item.section) {
        this.gotoSection(item.section)
      } else if (item.click) {
        item.click()
      }
    },
    clickSettings() {
      logger.debug('clickSetting')
      const user = this.cstate.p.user
      let path = '/m/settings'
      if (user.role == consts.ROLE_COMPANY) {
        path = `/c/${user.company_no}/edit`
      }
      this.$router.push(path)
    },
    logout() {
      this.$store.dispatch('common/' + types.LOGOUT)
    }
  },
  async created () {
    logger.debug('C4dAppBar.created')
  }
})

