
import Vue from 'vue'
import _ from 'lodash'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import { api } from '@/api'

interface ProgressPostReq {
  qno: number
}

export const member = {
  namespaced: true,
  state: {
    member: null,
    chapter: null,
    p: {
      currChapterNo: 0,
      progress: [
      ],
      totalProgress: 0,
      quizAnswers: {
      },
    }
  },
  getters: {
    quizAnswers(state, getters, rootState, rootGetters) {
      logger.debug('getter.quizAnswers')
      logger.debug('rootState=', rootState)
      const cstate = rootState.common
      logger.debug('cstate=', cstate)
      const chno = cstate.chapterNo
      return (qno) => {
        logger.debug(`getter.quizAnswers ch=${chno} qno=${qno}`)
        let a = []
        const chas = state.p.quizAnswers[chno]
        if (chas) {
          a = chas[qno - 1]
          if (a == null) {
            a = []
          }
        }
        return a
      }
    }
  },
  mutations: {
    [types.LOGOUT] (state, value) {
      logger.debug('M member/LOGOUT')
      state.p = {
        currChapterNo: 0,
        progress: [
        ],
        totalProgress: 0,
        quizAnswers: {
        },
      }
    },
    [types.MEMBER_SET] (state, value) {
      logger.debug('M member/MEMBER_SET')
      state.member = value
    },
    [types.PROGRESS_TOTAL] (state) {
      logger.debug('M member/PROGRESS_TOTAL')
      logger.debug('state.p.progress=', state.p.progress)
      state.p.totalProgress = state.p.progress.reduce((total, p) => {
        const rate = p ? p.rate : 0
        return total + rate
      }, 0)
      logger.debug(`state.p.totalProgress=${state.p.totalProgress}`)
    },
    [types.PROGRESS_SET] (state, value) {
      logger.debug('M member/PROGRESS_SET')
      state.p.progress = value.progress
      state.p.quizAnswers = value.quizAnswers
      logger.debug('quizAnswers=', state.p.quizAnswers)
      if (state.p.progress.length > 0) {
        logger.debug('progress=', state.p.progress)
        state.p.currChapterNo = state.p.progress.slice(-1)[0].chapter
      } else {
        state.p.currChapterNo = 0
      }
      logger.debug('currChapterNo=', state.p.currChapterNo)
    },
    [types.PROGRESS_GET] (state, value) {
      logger.debug('M member/PROGRESS_GET')
      state.p.progress = value.progress
      //state.p.currChapterNo = state.p.progress.length
    },
    [types.PROGRESS_POST] (state, value) {
      logger.debug('M member/PROGRESS_POST', JSON.stringify(value))
      const chno = value.chapterNo
      const prog = {
        chapter: chno,
        section: value.progress.section,
        rate: value.progress.rate
      }
      Vue.set(state.p.progress, chno - 1, prog)
      if (value.section === consts.SCT_QUIZ) {
        const qno = value.qno
        let chas = state.p.quizAnswers[chno]
        logger.debug(`M member/PROGRESS_POST answer=`, JSON.stringify(chas))
        if (!chas) {
          chas = []
        }
        chas[qno - 1] = value.answer
        state.p.quizAnswers[chno] = chas
        logger.debug(`M member/PROGRESS_POST ch:${chno} qno:${qno} answer=`, JSON.stringify(chas))
      }
    },
    [types.CHAPTER_SET] (state, value) {
      logger.debug('M member/CHAPTER_SET')
      state.p.currChapterNo = value
    }
  },
  actions: {
    async [types.MEMBER_GET] ({ state, commit, rootState }, user_id) {
      logger.debug('A member/MEMBER_GET')
      const res = await api.get(`/users/${user_id}`)
      if (res.status === 200 && res.data.status === 0) {
        const cstate = rootState.common
        if (cstate.p.user.id == user_id) {
          commit('common/' + types.ACCOUNT_GET, res.data.user, { root: true })
        }
        commit(types.MEMBER_SET, res.data.user)
      }
      return res
    },
    async [types.MEMBER_POST] ({ state, commit, rootState }, params) {
      logger.debug('A member/MEMBER_POST')
      const user_id = params.id
      const data = _.cloneDeep(params)
      delete data.icon
      const formData = new FormData()
      formData.append('json', JSON.stringify(data))
      if (params.icon) {
        formData.append('icon', params.icon)
      }
      const res = await api.post(`/users/${user_id}`, formData)
      if (res.status === 200 && res.data.status === 0) {
        const cstate = rootState.common
        if (cstate.p.user.id == user_id) {
          commit('common/' + types.ACCOUNT_GET, res.data.user, { root: true })
        }
        commit(types.MEMBER_SET, res.data.user)
      }
      return res
    },
    async [types.MEMBER_VERIFY] ({ state, commit, rootState }, params) {
      logger.debug('A member/MEMBER_VERIFY')
      const res = await api.post('/verify', params)
      if (res.status === 200 && res.data.status === 0) {
        commit('common/' + types.ACCOUNT_GET, res.data.user, { root: true })
      }
      return res
    },
    async [types.MEMBER_DEL] ({ state, commit, dispatch }, params) {
      logger.debug('A member/MEMBER_DEL')
      const user_id = params.id
      const res = await api.delete(`/users/${user_id}`)
      if (res.status === 200 && res.data.status === 0) {
        //commit(types.GROUP_SET, res.data.group)
      }
      return res
    },
    async [types.PROGRESS_GET] ({ state, commit, rootState }) {
      const res = await api.get('/users/me/progress')
      if (res.status === 200 && res.data.status === 0) {
        commit(types.PROGRESS_SET, res.data)
        commit(types.PROGRESS_TOTAL)
      }
    },
    async [types.PROGRESS_POST] ({ state, commit, rootState }, params) {
      logger.debug('A member/PROGRESS_POST')
      const cstate = rootState.common
      if (typeof params === 'string') {
        params = {
          section: params
        }
      }
      params = _.cloneDeep(params)
      params.chapterNo = cstate.chapterNo
      if (params.section == consts.SCT_QUIZ) {
        params.answer.sort()
      }
      const res = await api.post('/users/me/progress', params)
      if (res.status === 200) {
        params.progress = res.data.progress
        commit(types.PROGRESS_POST, params)
        commit(types.PROGRESS_TOTAL)
      }
    }
  }
}
