
import VueRouter, { RouteConfig } from 'vue-router'
import MyPage from '@/views/m/MyPage.vue'
import Summary from '@/views/m/learning/Summary.vue'
import Video from '@/views/m/learning/Video.vue'
import Point from '@/views/m/learning/Point.vue'
import QuizTop from '@/views/m/learning/QuizTop.vue'
import Quiz from '@/views/m/learning/Quiz.vue'
import QuizExp from '@/views/m/learning/QuizExp.vue'
import Achievement from '@/views/m/Achievement.vue'
import Complete from '@/views/m/Complete.vue'
import Team from '@/views/m/Team.vue'
import Settings from '@/views/Settings.vue'

export const member: Array<RouteConfig> = [
  {
    path: 'mypage',
    name: 'mypage',
    component: MyPage
  },
  {
    path: 'team',
    component: Team
  },
  {
    path: 'settings',
    component: Settings
  },
  {
    path: ':userId/settings',
    component: Settings
  },
  {
    path: 'complete',
    component: Complete
  },
  {
    path: 'chapter/:chapter/learning/summary',
    component: Summary
  },
  {
    path: 'chapter/:chapter/learning/video',
    component: Video
  },
  {
    path: 'chapter/:chapter/learning/point/:no',
    component: Point
  },
  {
    path: 'chapter/:chapter/learning/quiz-top',
    component: QuizTop
  },
  {
    path: 'chapter/:chapter/learning/quiz/:no',
    component: Quiz
  },
  {
    path: 'chapter/:chapter/learning/quiz-exp',
    component: QuizExp
  },
  {
    path: 'chapter/:chapter/achievement',
    component: Achievement
  },
]

