






















import Vue from 'vue'

import C4dMessage from '@/components/C4dMessage.vue'

export default Vue.extend({
  mixins: [
    //CommonMixin,
  ],
  components: {
    C4dMessage,
  },
  props: {
    value: Boolean
  },
  data () {
    return {
    }
  },
  created () {
    //
  },
  methods: {
    confirmed() {
      this.$emit('input', false)
    }
  }
})
