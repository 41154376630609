






























import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import MemberMixin from '@/mixins/member'

export default Vue.extend({
  props: {
    numer: {
      type: Number,
      default: 1
    },
    denom: {
      type: Number,
      default: 1
    },
  },
  data () {
    return {
    }
  },
  created () {
    const v = this.numer / this.denom
    logger.debug(`value=${this.numer}/${this.denom}=${v}`)
    //
  }
})
