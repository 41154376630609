



















































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import MemberViewMixin from '@/mixins/memberview'

export default Vue.extend({
  mixins: [
    MemberViewMixin,
  ],
  components: {
  },
  data() {
    return {
      company_name: null,
      message: null,
      show_pw: false,
      pwreset: '',
      form: {
        prelogin: true,
        company_no: this.$route.params.company_no,
        password: '',
        key: this.$route.query.key
      }
    }
  },
  async created() {
    logger.debug('CompanyLogin.created', this.$route.params.company_no)
    const res = await this.$store.dispatch('company/' + types.LOGIN, this.form)

    const company_no = this.$route.params.company_no
    const path = `/company/${company_no}/pwreset/1`
    this.pwreset = { path, query: this.$route.query }

    if (res.status === 200 && res.data.status === 0) {
      this.company_name = res.data.company.name
    }
  },
  methods: {
    async login() {
      logger.debug('CompanyLogin.login', this.$route.params.company_no)
      this.form.prelogin = false
      const res = await this.$store.dispatch('company/' + types.LOGIN, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.$router.push('/')
        //this.$router.push(`/c/${this.form.company_no}`)
      }
    },
  }
})

