
import axios from 'axios'

import router from '@/router'
import store from '@/store'
import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { VERSION } from '@/version'
import config from '@/config'

let globalLoading = false

function _loading (flag: boolean) {
  store.commit('common/' + types.LOADING, flag)
}

function loading (flag: boolean) {
  if (globalLoading) {
    return
  }
  _loading(flag)
}

const API_PATH = config.API_URL_PREFIX + '/api'

class Api {

  loading(flag: boolean) {
    _loading(flag)
    globalLoading = flag
  }

  buildUrl(path: string): string {
    if (/^https?:\/\//.test(path)) {
      return path
    } else if (/^\/\//.test(path)) {
      return config.API_URL_PREFIX + path.slice(1)
    }
    return API_PATH + path
  }

  async get(path: string, config?: any): Promise<any> {
    config = this.buildConfig(config)
    loading(true)
    try {
      const url = this.buildUrl(path)
      const res = await axios.get(url, config)
      logger.debug(`api.get ${path} = ${res.status}`)
      this.check_error(res)
      return res
    } catch (e) {
      return this.error(e)
    } finally {
      loading(false)
    }
  }

  async post(path: string, data: any, config?: any): Promise<any> {
    config = this.buildConfig(config)
    if (path.indexOf('/login') === 0) {
      logger.debug(`api.post ${path}`)
    } else {
      logger.debug(`api.post ${path} data=` + JSON.stringify(data, null, '  '))
    }
    loading(true)
    try {
      const url = this.buildUrl(path)
      const res = await axios.post(url, data, config)
      logger.debug(`api.post ${path} = ${res.status}`)
      this.check_error(res)
      return res
    } catch (e) {
      return this.error(e)
    } finally {
      loading(false)
    }
  }

  async put(path: string, data: any, config?: any): Promise<any> {
    config = this.buildConfig(config)
    loading(true)
    try {
      const url = this.buildUrl(path)
      const res = await axios.put(url, data, config)
      logger.debug(`api.put ${path} = ${res.status}`)
      this.check_error(res)
      return res
    } catch (e) {
      return this.error(e)
    } finally {
      loading(false)
    }
  }

  async delete(path: string, config?: any): Promise<any> {
    config = this.buildConfig(config)
    loading(true)
    try {
      const url = this.buildUrl(path)
      const res = await axios.delete(url, config)
      logger.debug(`api.delete ${path} = ${res.status}`)
      this.check_error(res)
      return res
    } catch (e) {
      return this.error(e)
    } finally {
      loading(false)
    }
  }

  error(e: any) {
    logger.error('api error e=' + JSON.stringify(e))
    console.log('store=', store)
    store.commit('common/' + types.ERROR, 'ネットワークに接続されていません。ネットワーク環境のご確認をお願いします。')
    return {status: -1}
  }

  buildConfig(config?: any): string {
    if (!config) {
      config = { headers: {} }
    }
    if (!config.headers) {
      config.headers = {}
    }
    const token = store.state.common.p.token
    if (token) {
      config.headers['Authorization'] = token
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['X-CX4DX-Version'] = VERSION
    config.validateStatus = (status: number) => {
      logger.debug(`validateStatus: status=${status}`)
      return true
    }
    return config
  }

  check_error(res: any) {
    if (res.status === 401 || res.status === 403) {
      const path = router.currentRoute.path
      if (path.indexOf('/login') < 0) {
        logger.error('logout')
        store.dispatch('common/' + types.LOGOUT)
        return
      }
    }
    logger.debug('res=', res)
    let error = ''
    if (res.status === 200) {
      if (res.data.status !== 0) {
        error = res.data.error
      }
      const new_token = res.headers['x-cx4dx-token']
      if (new_token) {
        logger.debug('token refreshed')
        store.commit('common/' + types.TOKENN_REFRESH, new_token)
      }
    } else {
        let t = new Date().getTime()
        t = parseInt(t / 1000)
        error = `システムエラーが発生しました。(C${t})`
    }
    store.commit('common/' + types.ERROR, error)
  }
}

export const api = new Api()
