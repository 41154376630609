






























import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import MemberMixin from '@/mixins/member'

export default Vue.extend({
  mixins: [
    MemberMixin
  ],
  components: {
  },
  props: {
    title: String,
  },
  data() {
    return {
    }
  },
  created () {
    logger.debug('C4dContainer.created')
  }
})

