












































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  components: {
  },
  data() {
    return {
      done: false,
      message: null,
      show_pw: false,
      password_confirm: '',
      form: {
        password: '',
        code: this.$route.query.code
      }
    }
  },
  created () {
    logger.debug('Pwreset2.created')
    this.showMessage_ = false
    const code = this.$route.query.code
    if (!code) {
      this.$router.replace('/')
    }
  },
  methods: {
    async register() {
      if (!this.$refs.form.validate()) {
        return
      }
      const res = await this.$store.dispatch('common/' + types.PWRESET_S2, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.done = true
        this.message = ' パスワードを再設定しました。'
      }
      this.showMessage()
    },
    confirmed() {
      this.showConfirm(false)
      if (this.done) {
        this.$router.replace('/')
      }
    }
  }
})

