
import Vue from 'vue'
import _ from 'lodash'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import { api } from '@/api'

export const group = {
  namespaced: true,
  state: {
    group: null,
    memberList: [],
  },
  mutations: {
    [types.GROUP_SET] (state, value) {
      logger.debug('M group/GROUP_SET')
      if (!state.group) {
        state.group = {}
      }
      state.group = _.merge(state.group, value)
    },
    [types.MEMBER_LIST] (state, value) {
      logger.debug('M group/MEMBER_LIST')
      state.memberList = value
      for (const m of state.memberList) {
        if (!m.icon_url) {
          m.icon_url = '/images/icon_account_me.svg'
        }
      }
      if (!state.group.progres) {
        let total = 0
        for (const m of state.memberList) {
          total += m.progress
        }
        let progress = 0
        if (value.length) {
          progress = Math.round(total / value.length)
        }
        Vue.set(state.group, 'progress', progress)
      }
    },
  },
  actions: {
    async [types.PROGRESS_GET] ({ state, commit }, params) {
      logger.debug('A group/PROGRESS_GET')
      const group_id = params.group_id
      const res = await api.get(`/groups/${group_id}/progress`, params)
      return res
    },
    async [types.GROUP_GET] ({ state, commit, dispatch }, group_id) {
      logger.debug('A GROUP/GROUP_GET')
      const res = await api.get(`/groups/${group_id}`)
      if (res.status === 200) {
        commit(types.GROUP_SET, res.data.group)
      } else {
        commit(types.GROUP_SET, null)
      }
      return res
    },
    async [types.GROUP_POST] ({ state, commit, dispatch }, params) {
      logger.debug('A group/GROUP_POST')
      const company_no = params.company_no
      const group_id = params.group_id
      delete params.company_no
      delete params.group_id
      const res = await api.post(`/companies/${company_no}/groups/${group_id}`, params)
      if (res.status === 200 && res.data.status === 0) {
        commit(types.GROUP_SET, res.data.group)
      }
      return res
    },
    async [types.GROUP_DEL] ({ state, commit, dispatch }, params) {
      logger.debug('A group/GROUP_DEL')
      const company_no = params.company_no
      const group_id = params.group_id
      const res = await api.delete(`/groups/${group_id}`)
      if (res.status === 200 && res.data.status === 0) {
        //commit(types.GROUP_SET, res.data.group)
      }
      return res
    },
    async [types.MEMBER_LIST] ({ state, commit, dispatch }, groupId) {
      logger.debug('A group/MEMBER_LIST')
      const res = await api.get(`/groups/${groupId}/users`)
      if (res.status === 200) {
        commit(types.MEMBER_LIST, res.data.members)
      }
      return res
    },
  }
}
