










































import Vue from 'vue'

import { logger } from '@/logger'
import CommonMixin from '@/mixins/common'

export default Vue.extend({
  mixins: [
    CommonMixin,
  ],
  props: {
    show: Boolean,
  },
  data() {
    return {
      show_: this.show
    }
  },
  watch: {
    show(to, from) {
      this.setShow(to)
    }
  },
  created() {
    logger.debug('C4dMessage.created')
    logger.debug('show=', this.show, this.show_)
  },
  methods: {
    setShow(v) {
      this.show_ = v
      if (!v) {
        this.$emit('close')
      }
    }
  }
})
