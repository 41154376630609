














export default {
  mixins: [
  ],
  created () {
    console.log('Base.created')
  }
}

