
























import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  components: {
  },
  data() {
    return {
      verified: false,
      message: null,
    }
  },
  async created () {
    logger.debug('Verify.created')
    this.showMessage_ = false
    const code = this.$route.query.code
    const res = await this.$store.dispatch('common/' + types.SIGNUP_VERIFY, code)
    this.message = this.cstate.error
    if (res.status == 200 && res.data.status === 0) {
      this.message = 'メールによる確認を完了しました。<br>再度ログインをお願いします。'
      this.verified = true
    }
    this.showMessage()
  },
  methods: {
    confirmed() {
      this.showConfirm(false)
      this.$router.replace('/')
    }
  }
})

