







































































































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import MemberViewMixin from '@/mixins/memberview'

export default Vue.extend({
  mixins: [
    MemberViewMixin,
  ],
  components: {
  },
  data() {
    return {
      prog: {
        section: consts.SCT_QUIZ,
        answer: [],
        qno: 0,
      },
      quiz: {},
      maxCorrect: 1,
    }
  },
  created () {
    logger.debug('Quiz.created')
    this.routeChanged()
  },
  methods: {
    routeChanged() {
      console.log('Quiz.routeChanged')
      const qno = parseInt(this.$route.params.no)
      this.prog.qno = qno
      this.prog.answer = []
      this.quiz = this.cstate.chapter.quizes[qno - 1]
      this.maxCorrect = this.quiz.correct.length
      console.log(`Q ${qno} answer=${this.prog.answer}`)
      console.log(`Q ${qno} maxCorrect=${this.maxCorrect}`)
    },
    async checkToggle(active, toggle, value) {
      let pa = JSON.stringify(this.prog.answer)
      if (this.maxCorrect == 1) {
        this.prog.answer = [value]
        if (!active) {
          toggle()
        }
      } else {
        toggle()
      }
      let ca = JSON.stringify(this.prog.answer)
      logger.debug(`Quiz.checkToggle active=${active} choice=${pa} -> ${ca}`)

      if (this.prog.answer.length == this.maxCorrect) {
        await this.$store.dispatch('member/' + types.PROGRESS_POST, this.prog);
        this.goNext()
      }
    },
    beforeNext() {
      logger.debug('Quiz.beforeNext')
    },
  }
})

