
class Logger {
  INFO = 1
  DEBUG = 0

  _logLevel = 0

  logLevel(ll: number) {
    this._logLevel = ll
  }

  ndebug(...args: any[]) {
    // nop
  }

  debug(...args: any[]) {
    if (this._logLevel <= this.DEBUG) {
      args.splice(0, 0, '[DEBUG]')
      console.log.apply(null, args)
    }
  }

  info(...args: any[]) {
    if (this._logLevel <= this.INFO) {
      args.splice(0, 0, '[INFO]')
      console.info.apply(null, args)
    }
  }

  error(...args: any[]) {
    args.splice(0, 0, '[ERROR]')
    console.error.apply(null, args)
  }

}

export const logger = new Logger()
