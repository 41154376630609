
import VueRouter, { RouteConfig } from 'vue-router'
import Top from '@/views/g/Top.vue'
import Group from '@/views/g/Group.vue'
import Team from '@/views/m/Team.vue'

export const group: Array<RouteConfig> = [
  {
    path: ':companyNo/new',
    name: 'new-group',
    component: Group
  },
  {
    path: ':companyNo/:groupId',
    name: 'top',
    component: Top
  },
  {
    path: ':companyNo/:groupId/edit',
    name: 'group',
    component: Group
  },
  {
    path: ':companyNo/:groupId/team',
    name: 'group',
    component: Team
  },
]

