




























import Vue from 'vue';
import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import CommonMixin from '@/mixins/common'
import C4dAppBar from '@/components/C4dAppBar.vue'

export default Vue.extend({
  name: 'App',
  mixins: [
    CommonMixin,
  ],
  components: {
    C4dAppBar,
  },
  async created () {
    logger.debug('App.created')
    logger.debug('p.user=', this.cstate.p.user)
    if (!this.cstate.p.user) {
      if (this.isAuthRequired()) {
        this.$router.push('/')
      }
    } else {
      this.$store.commit('member/' + types.MEMBER_SET, this.cstate.p.user)
      await this.$store.dispatch('common/' + types.CHAPTER_LIST)
    }
  }
});
