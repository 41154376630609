


























































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminMixin from '@/mixins/admin'
import C4dBtn from '@/components/C4dBtn.vue'

export default Vue.extend({
  mixins: [
    AdminMixin,
  ],
  components: {
    C4dBtn,
  },
  props: {
    company: Object,
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      url: ''
    }
  },
  created () {
    logger.debug('C4dCompanyCard.created', JSON.stringify(this.company))
    const origin = location.origin
    if (this.company.secretkey) {
      this.url = `${origin}/#/company/${this.company.no}/login?key=${this.company.secretkey}`
    }
  },
  methods: {
    click() {
      this.$store.commit('company/' + types.COMPANY_SET, this.company)
      const path = `/c/${this.company.no}`
      if (this.edit) {
        this.$router.push(path + '/edit')
      } else {
        this.$router.push(path)
      }
    }
  }
})

