import Vue from 'vue'

import C4dBtn from '@/components/C4dBtn.vue'
import C4dContainer from '@/components/C4dContainer.vue'
import C4dChapterCard from '@/components/C4dChapterCard.vue'
import C4dChapterTeamCard from '@/components/C4dChapterTeamCard.vue'
import C4dNavigation from '@/components/C4dNavigation.vue'
import C4dCol from '@/components/C4dCol.vue'
import C4dDoughnut from '@/components/C4dDoughnut.vue'
import C4dImg from '@/components/C4dImg.vue'
import C4dHeading from '@/components/C4dHeading.vue'
import C4dMessage from '@/components/C4dMessage.vue'
import C4dConfirm from '@/components/C4dConfirm.vue'
import C4dCompanyAccount from '@/components/C4dCompanyAccount.vue'
import C4dCompanyCard from '@/components/C4dCompanyCard.vue'
import C4dGroupCard from '@/components/C4dGroupCard.vue'
import C4dMemberCard from '@/components/C4dMemberCard.vue'
import C4dImageUploader from '@/components/C4dImageUploader.vue'
import C4dTos from '@/components/C4dTos.vue'

export default Vue.extend({
  components: {
    C4dBtn,
    C4dContainer,
    C4dChapterCard,
    C4dChapterTeamCard,
    C4dNavigation,
    C4dCol,
    C4dDoughnut,
    C4dImg,
    C4dHeading,
    C4dMessage,
    C4dConfirm,
    C4dCompanyAccount,
    C4dCompanyCard,
    C4dGroupCard,
    C4dMemberCard,
    C4dImageUploader,
    C4dTos,
  }
})

