
import axios from 'axios'
import _ from 'lodash'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import { api } from '@/api'
import router from '@/router'


export const common = {
  namespaced: true,
  state: {
    p: {
      chapterList: [],
      user: null,
      token: null,
    },
    chapter: null,
    chapterNo: -1,
    npoints: 0,
    nquizes: 0,
    loading: false,
    chapterListLmt: '',
    error: '',
  },
  mutations: {
    [types.LOADING] (state, loading) {
      logger.debug('M c/LOADING')
      state.loading = loading
    },
    [types.ACCOUNT_GET] (state, user) {
      logger.debug('M common/ACCOUNT_GET')
      if (!user) {
        return
      }
      if (state.p.user && !user.company_no) {
        user.company_no = state.p.user.company_no
      }
      if (user.icon) {
        if (state.p.user && user.icon === state.p.user.icon) {
          user.icon_url = state.p.user.icon_url
          logger.debug('M common/ACCOUNT_GET not changed')
        } else {
          const img = new Image()
          img.src = user.icon_url
          img.onload = (e) => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = img.width
            canvas.height = img.height
            ctx.drawImage(img, 0, 0, img.width, canvas.height)
            state.p.user.icon_url = canvas.toDataURL()
          }
        }
      } else {
        user.icon_url = '/images/icon_account_me.svg'
      }
      state.p.user = user
    },
    [types.LOGIN] (state, value) {
      logger.debug('M common/LOGIN')
      state.p.token = value.token
    },
    [types.LOGOUT] (state, value) {
      logger.debug('M common/LOGOUT')
      state.p = {
        chapterList: [],
        user: null,
        token: null,
      }
      const gotologin = typeof value === 'undefined' ? true : value
      if (gotologin) {
        router.push('/', () => {
          //
        })
      }
    },
    [types.TOKENN_REFRESH] (state, value) {
      logger.debug('M common/TOKENN_REFRESH')
      state.p.token = value
    },
    [types.ERROR] (state, value) {
      logger.debug('M common/ERROR')
      state.error = value
    },
    [types.CHAPTER_LIST] (state, value) {
      logger.debug('M common/CHAPTER_LIST')
      state.p.chapterList = value.data
      if (value.lmt) {
        const d = new Date(value.lmt)
        state.chapterListLmt = d.toLocaleString()
      }
    },
    [types.CHAPTER_SET] (state, value) {
      logger.debug('M common/CHAPTER_SET')
      if (value) {
        state.chapterNo = value
        state.chapter = state.p.chapterList[value - 1];
        state.npoints = state.chapter.lectures.length
        state.nquizes = state.chapter.quizes.length
      } else {
        state.chapterNo = -1
        state.chapter = null
        state.npoints = 0
        state.nquizes = 0
      }
    }
  },
  actions: {
    async [types.LOGIN] ({ state, commit, dispatch }, params) {
      logger.debug('A common/LOGIN')
      const res = await api.post('/login', params)
      if (res.status === 200 && res.data.status === 0) {
        commit(types.LOGIN, res.data)
        commit(types.ACCOUNT_GET, res.data.user)
        commit('company/' + types.LOGIN, null, { root: true })
        if (state.p.user.role == consts.ROLE_USER) {
          commit('member/' + types.MEMBER_SET, res.data.user, { root: true })
          commit('member/' + types.PROGRESS_SET, res.data, { root: true })
          commit('member/' + types.PROGRESS_TOTAL, null, { root: true })
        } else if (state.p.user.role == consts.ROLE_COMPANY) {
          commit('company/' + types.LOGIN, res.data.company, { root: true })
        }
        await dispatch(types.CHAPTER_LIST)
      }
      return res
    },
    [types.LOGOUT] ({ state, commit }, params) {
      commit(types.LOGOUT, params)
      commit('member/' + types.LOGOUT, null, { root: true })
    },
    async [types.SIGNUP_ISSUE] ({ state, commit, dispatch }, params) {
      logger.debug('A common/SIGNUP_ISSUE')
      const res = await api.post('/signup_code', params)
      return res
    },
    async [types.SIGNUP_CHECK] ({ state, commit, dispatch }, code) {
      logger.debug('A common/SIGNUP_CHECK')
      const res = await api.post('/signup/check', { code })
      return res
    },
    async [types.SIGNUP_TEMP] ({ state, commit, dispatch }, params) {
      logger.debug('A common/SIGNUP_TEMP')
      const data = _.cloneDeep(params)
      delete data.icon
      const formData = new FormData()
      formData.append('json', JSON.stringify(data))
      if (params.icon) {
        formData.append('icon', params.icon)
      }
      const res = await api.post('/signup/temporary', formData)
      return res
    },
    async [types.SIGNUP_VERIFY] ({ state, commit, dispatch }, code) {
      logger.debug('A common/SIGNUP_VERIFY')
      const res = await api.post('/signup/verify', { code })
      return res
    },
    async [types.PWRESET_S1] ({ state, commit }, params) {
      const res = await api.post('/pwreset/user', params)
      return res
    },
    async [types.PWRESET_S2] ({ state, commit }, params) {
      const res = await api.post('/pwreset/user/done', params)
      return res
    },
    async [types.CHAPTER_LIST] ({ commit }, params) {
      logger.debug('A common/CHAPTER_LIST')
      //const res = await api.get('//chapters.json', params)
      const res = await axios.get('/chapters.json')
      logger.debug('A common/CHAPTER_LIST res=', res)
      if (res.status === 200) {
        const value = {
          lmt: res.headers['last-modified'],
          data: res.data,
        }
        commit(types.CHAPTER_LIST, value)
      } else {
        commit(types.CHAPTER_LIST, [])
      }
      return res
    },
  },
}
