











































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminMixin from '@/mixins/admin'

export default Vue.extend({
  mixins: [
    AdminMixin,
  ],
  components: {
  },
  props: {
    company: Object,
  },
  data() {
    return {
    }
  },
  created () {
    logger.debug('C4dCompanyAccount.created')
  },
  methods: {
    detail() {
      this.$store.commit('company/' + types.COMPANY_SET, this.company)
      this.$router.push(`/c/${this.company.no}`)
    }
  }
})

