
import VueRouter, { RouteConfig } from 'vue-router'
import Top from '@/views/c/Top.vue'
import Company from '@/views/c/Company.vue'

export const company: Array<RouteConfig> = [
  {
    path: 'new',
    name: 'new-company',
    component: Company
  },
  {
    path: ':companyNo',
    name: 'top',
    component: Top
  },
  {
    path: ':companyNo/edit',
    name: 'company',
    component: Company
  },
]

