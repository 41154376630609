














import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  components: {
  },
  data() {
    return {
      show: false,
      message: null,
    }
  },
  async created () {
    logger.debug('Account.created')
    this.showMessage_ = false
    logger.debug('query=', this.$route.query)
    const type = this.$route.query.type
    const code = this.$route.query.code

    if (this.cstate.p.user) {
      await this.$store.dispatch('common/' + types.LOGOUT, false)
    }

    if (type !== 'signup') {
      let path = '/' + type
      this.$router.replace({ path, query: this.$route.query })
      return
    }

    const res = await this.$store.dispatch('common/' + types.SIGNUP_CHECK, code)
    this.message = this.cstate.error
    if (res.status == 200 && res.data.status === 0) {
      this.$router.replace({ path: 'signup', query: this.$route.query })
    } else {
      this.showMessage()
    }
  },
  methods: {
    async register() {
       if (!this.$refs.form.validate()) {
         return
       }
    },
    close() {
      logger.debug('close!!')
    }
  }
})

