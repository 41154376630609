














import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import MemberMixin from '@/mixins/member'

export default Vue.extend({
  props: {
    mobile: {
      type: [Number, String],
      default: 12
    },
    pc: {
      type: [Number, String],
      default: 12
    },
    offsetPc: {
      type: [Number, String],
    }
  },
  data () {
    return {
    }
  },
  created () {
    //
  }
})
