




















import Vue from 'vue';
import { logger } from '@/logger'
import { consts } from '@/consts'
import { types } from '@/store/mutation-types'
import CommonMixin from '@/mixins/common'

export default Vue.extend({
  name: 'App',
  mixins: [
    CommonMixin,
  ],
  created () {
    logger.debug('Top.created user=', this.cstate.p.user)
    logger.debug('Top.created company=', this.kstate.company)
    const user = this.cstate.p.user
    let path = '/login'
    if (user) {
      switch (user.role) {
        case consts.ROLE_ADMIN:
          path = '/a'
          break;

        case consts.ROLE_COMPANY:
          if (this.kstate.company) {
            path = `/c/${this.kstate.company.no}`
          } else {
            path = '/c'
          }
          break;

        case consts.ROLE_GROUP:
          path = `/g/${user.company_no}/${user.group_id}`
          break;

        case consts.ROLE_USER:
          path = '/m/mypage'
          break;
      }
    } else {
      logger.debug('login_path=', this.kstate.login_path)
      if (this.kstate.p.login_path) {
        path = this.kstate.p.login_path
      } else {
        path = '/login'
      }
    }
    if (path) {
      this.$router.replace(path)
    }
  }
})

