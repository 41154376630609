import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import axios from 'axios'

import { logger } from '@/logger'
import { api } from '@/api'
import { types } from '@/store/mutation-types'
import { common } from './common'
import { member } from './member'
import { group } from './group'
import { company } from './company'
import { admin } from './admin'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    common,
    member,
    group,
    company,
    admin,
  },
  plugins: [
    createPersistedState({
      key: 'cx4dx',
      paths: [
        'common.p',
        'member.p',
        'company.p',
      ]
    })
  ]
})
