import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'md'
  },
  theme: {
    themes: {
      light: {
        color1: '#5065ff',
        color2: '#eb518c',
        color3: '#d6eaff',
        accent: '#e82872',
        text2: '#3d3f59',
      }
    }
  }
});
