



















import Vue from 'vue';
import { logger } from '@/logger'

export default Vue.extend({
  mixins: [
  ],
  components: {
  },
  created () {
    console.log('MemberBase.created')
  },
  methods: {
  }
})

