<template>
  <v-dialog
      v-bind="$attrs"
      persistent
      max-width="50%"
      :fullscreen="mobile"
      v-model="show_">
    <v-card>
      <v-toolbar
          color="primary"
          >
      </v-toolbar>
        <v-container class="pa-8">
          <v-row justify="center">
            <v-col cols="10" class="py-0 text-center font-dialog-title">
              {{title}}
            </v-col>
          </v-row>
          <v-row justify="center" class="pt-4">
            <v-col cols="10" class="py-0 font-dialog-message text-center">
              <slot />
            </v-col>
          </v-row>
          <v-row justify="center" class="pt-8">
            <v-col cols="4" class="py-0 text-center">
              <c4d-btn
                  type="cancel"
                  @click="click_cancel"
                  >
                  NO
              </c4d-btn>
            </v-col>
            <v-col cols="4" class="py-0 text-center">
              <c4d-btn
                  @click="click_ok"
                  >
                  YES
              </c4d-btn>
            </v-col>
          </v-row>
        </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import Vue from 'vue'

import AdminMixin from '@/mixins/admin'
import C4dBtn from '@/components/C4dBtn.vue'

export default Vue.extend({
  mixins: [
    AdminMixin,
  ],
  components: {
    C4dBtn,
  },
  props: {
    show: Boolean,
    title: String
  },
  data () {
    return {
      show_: this.show
    }
  },
  watch: {
    show: {
      immediate: true,
      handler () {
        console.log('show changed!', this.show)
        this.show_ = this.show
      }
    }
  },
  methods: {
    click_ok () {
      this.show_ = false
      this.$emit('ok')
    },
    click_cancel () {
      this.show_ = false
      this.$emit('cancel')
    }
  }
})
</script>
