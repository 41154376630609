








































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import MemberMixin from '@/mixins/member'
import C4dCol from '@/components/C4dCol.vue'
import C4dImg from '@/components/C4dImg.vue'

export default Vue.extend({
  mixins: [
    MemberMixin
  ],
  components: {
    C4dCol,
    C4dImg,
  },
  props: {
    disabledNext: {
      type: Boolean,
      default: false,
    },
    disabledPrev: {
      type: Boolean,
      default: false,
    },
    showNext: {
      type: Boolean,
      default: true,
    },
    showPrev: {
      type: Boolean,
      default: true,
    },
    clickNext: Function,
    clickPrev: Function,
    beforeNext: Function,
    progress: [String, Object],
  },
  data() {
    return {
      to_next: '',
      to_prev: '',
    }
  },
  created () {
    logger.debug('C4dNavigation.created', this.chapter)
    const base = `/m/chapter/${this.cstate.chapterNo}/`
    this.to_prev = base + this.prev
    this.to_next = base + this.next
  },
  methods: {
    async handleNext() {
      if (this.clickNext) {
        this.clickNext()
      } else {
        if (this.beforeNext) {
          this.beforeNext()
        }
        if (this.progress) {
          logger.debug('progress=', this.progress)
          await this.$store.dispatch('member/' + types.PROGRESS_POST, this.progress);
        }
        this.goNext()
      }
    },
    handlePrev() {
      if (this.clickPrev) {
        this.clickPrev()
      } else {
        this.goPrev()
      }
    }
  }
})

