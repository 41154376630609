
export const consts = {
  SCT_SUMMARY: 'summary',
  SCT_VIDEO: 'video',
  SCT_POINT: 'point',
  SCT_QUIZ: 'quiz',
  SCT_QUIZ_EXP: 'quiz_exp',
  SCT_COMPLETE: 'complete',

  ROLE_ADMIN: 1,
  ROLE_COMPANY: 2,
  ROLE_GROUP: 3,
  ROLE_USER: 4,

  //PROGRESS_FREE: true,
  PROGRESS_FREE: false,
}

