import { render, staticRenderFns } from "./Top.vue?vue&type=template&id=711c96db&scoped=true&"
import script from "./Top.vue?vue&type=script&lang=ts&"
export * from "./Top.vue?vue&type=script&lang=ts&"
import style0 from "./Top.vue?vue&type=style&index=0&id=711c96db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../Users/hashi/Nextcloud/vagrant/shared/cx4dx/client/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "711c96db",
  null
  
)

export default component.exports