
import Vue from 'vue'
import _ from 'lodash'

import router from '@/router'
import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import { api } from '@/api'

export const company = {
  namespaced: true,
  state: {
    company: null,
    groupList: [],
    p: {
      login_path: '',
    }
  },
  mutations: {
    [types.COMPANY_SET] (state, value) {
      logger.debug('M company/COMPANY_SET')
      if (!state.company) {
        state.company = {}
      }
      state.company = _.merge(state.company, value)
    },
    [types.LOGIN] (state, value) {
      logger.debug('M company/LOGIN', value)
      state.company = value
      if (value) {
        state.p.login_path = router.currentRoute.fullPath
      } else {
        state.p.login_path = null
      }
    },
    [types.GROUP_LIST] (state, value) {
      logger.debug('M company/GROUP_LIST')
      state.groupList = value
      let total = 0
      for (const g of state.groupList) {
        total += g.progress
      }
      const progress = Math.round(total / value.length)
      Vue.set(state.company, 'progress', progress)
    },
  },
  actions: {
    async [types.LOGIN] ({ state, commit, dispatch }, params) {
      logger.debug('A company/LOGIN')
      const res = await api.post('/company/login', params)
      commit(types.LOGIN, res.data.company)
      commit('common/' + types.LOGIN, res.data, { root: true })
      commit('common/' + types.ACCOUNT_GET, res.data.user, { root: true })
      await dispatch('common/' + types.CHAPTER_LIST, null, { root: true })
      return res
    },
    async [types.PWRESET_S1] ({ state, commit }, params) {
      const res = await api.post('/pwreset/company', params)
      return res
    },
    async [types.PWRESET_S2] ({ state, commit }, params) {
      const res = await api.post('/pwreset/company/done', params)
      return res
    },
    async [types.COMPANY_GET] ({ state, commit, dispatch }, company_no) {
      logger.debug('A company/COMPANY_GET')
      const res = await api.get(`/companies/${company_no}`)
      if (res.status === 200) {
        commit(types.COMPANY_SET, res.data.company)
      } else {
        commit(types.COMPANY_SET, null)
      }
      return res
    },
    async [types.COMPANY_POST] ({ state, commit, dispatch, rootState }, params) {
      logger.debug('A company/COMPANY_POST')
      let company_no = 'new'
      if (params.no) {
        company_no = params.no
      }
      const res = await api.post(`/companies/${company_no}`, params)
      if (res.status === 200 && res.data.status === 0) {
        const company = res.data.company
        commit(types.COMPANY_SET, company)
        const user = _.cloneDeep(rootState.common.p.user)
        user.name = company.pic
        user.email = company.email
        if (user.role == consts.ROLE_COMPANY) {
          commit('common/' + types.ACCOUNT_GET, user, { root: true })
        }
      }
      return res
    },
    async [types.COMPANY_DEL] ({ state, commit, dispatch }, params) {
      logger.debug('A company/COMPANY_DEL')
      const company_no = params.no
      const res = await api.delete(`/companies/${company_no}`, params)
      if (res.status === 200 && res.data.status === 0) {
        commit(types.COMPANY_SET, res.data.company)
      }
      return res
    },
    async [types.GROUP_LIST] ({ state, commit, dispatch }, company_no) {
      logger.debug('A company/GROUP_LIST')
      const res = await api.get(`/companies/${company_no}/groups`)
      if (res.status === 200) {
        commit(types.GROUP_LIST, res.data.groups)
      }
      return res
    },
  }
}
