













































































import Vue from 'vue'
import Hls from 'hls.js'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import MemberViewMixin from '@/mixins/memberview'

export default Vue.extend({
  mixins: [
    MemberViewMixin,
  ],
  components: {
  },
  data() {
    return {
      hls: null,
      videoStart: false,
      played: false,
    }
  },
  created () {
    logger.debug('Video.created')
  },
  mounted () {
    logger.debug('Video.mounted')
  },
  methods: {
    play() {
      const video = this.$refs.video
      video.volume = 0.5
      if (Hls.isSupported()) {
        logger.debug('Hls supported')
        this.hls = new Hls()
        this.hls.loadSource(this.cstate.chapter.video)
        this.hls.attachMedia(video)
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play()
          this.requestFullScreen()
        })
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.cstate.chapter.video
        video.addEventListener('loadedmetadata', () => {
          video.play()
          this.requestFullScreen()
        })
      } else {
        //
      }
      this.videoStart = true
      this.played = true
    },
    playDone() {
      logger.debug('play done')
      this.exitFullScreen()
      this.played = true
      this.$store.dispatch('member/' + types.PROGRESS_POST, consts.SCT_VIDEO);
    },
    requestFullScreen() {
      const video = this.$refs.video
      if (video.requestFullScreen) {
        console.log('full a')
        video.requestFullScreen();
      } else if (video.webkitRequestFullScreen) {
        console.log('full b')
        video.webkitRequestFullScreen();
      } else if (video.webkitEnterFullscreen) {
        console.log('full c')
        video.webkitEnterFullscreen();
      }else if (video.mozRequestFullScreen) {
        console.log('full d')
        video.mozRequestFullScreen();
      }else if (video.msRequestFullscreen) {
        console.log('full e')
        video.msRequestFullscreen();
      }
    },
    exitFullScreen(){
      document.exitFullscreen()
      /*
      const video = this.$refs.video
      if (video.exitFullscreen) {
        console.log('full a')
        video.exitFullscreen();
      } else if (video.cancelFullScreen) {
        console.log('full b')
        video.cancelFullScreen();
      }else if (video.mozCancelFullScreen) {
        console.log('full c')
        video.mozCancelFullScreen();
      }else if (video.webkitCancelFullScreen) {
        console.log('full d')
        video.webkitCancelFullScreen();
      }else if (video.msExitFullscreen) {
        console.log('full e')
        video.msExitFullscreen();
      }
      */
    }
  }
})

