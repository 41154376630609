


















































































































import Vue from 'vue'
import _ from 'lodash'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  data() {
    return {
      new_company: false,
      btn: '保存',
      done: false,
      mode: '',
      confirm: '',
      message: null,
      form: {
        name: '',
        pic: '',
        email: '',
        max_groups: 0,
        max_users: 0,
      },
    }
  },
  computed: {
    changed() {
      return !_.isEqual(this.form, this.kstate.company)
    }
  },
  created () {
    const companyNo = this.$route.params.companyNo
    logger.debug('Company.created', companyNo)
    if (!companyNo) {
      this.btn = '登録'
      this.new_company = true
      this.form = {
        name: '',
        pic: '',
        email: '',
        max_groups: 0,
        max_users: 0,
      }
    } else if (this.kstate.company) {
      this.form = _.cloneDeep(this.kstate.company)
    }
  },
  methods: {
    cancel() {
      this.showConfirm(false)
    },
    action(mode) {
      this.mode = mode
      this.confirm = null
      switch (this.mode) {
        case 'register':
          if (!this.$refs.form.validate()) {
            return
          }
          if (this.new_company) {
            this.confirm = '企業管理者に登録確認メールを送信します。よろしいですか。'
          } else {
            this.doPost()
          }
          break
        case 'delete':
          this.confirm = '企業内の全利用者のアカウントを削除します。よろしいですか。'
          break
      }
      if (this.confirm) {
        this.showConfirm()
      }
    },
    doAction() {
      switch (this.mode) {
        case 'register':
          this.doPost()
          break
        case 'delete':
          this.doDelete()
      }
    },
    /*
    register() {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.new_company) {
        this.action('register')
      } else {
        this.doPost()
      }
    },
    */
    async doPost() {
      this.showConfirm(false)
      const res = await this.$store.dispatch('company/' + types.COMPANY_POST, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.done = true
        if (this.new_company) {
          this.message = 'CX4DX企業管理者登録確認メールを送信しました。'
        } else {
          this.message = '企業情報を登録しました。'
        }
      }
      this.showMessage()
    },
    confirmed() {
      if (this.done) {
        switch (this.mode) {
          case 'register':
            if (this.new_company) {
              this.$router.push('/a')
            } else {
              this.$router.back()
            }
            break
          case 'delete':
            this.$router.push('/a')
            break
        }
      } else {
        this.showMessage(false)
      }
    },
    deleteCompany() {
      this.confirm = '企業内の全利用者のアカウントを削除します。よろしいですか。'
      this.showConfirm()
    },
    async doDelete() {
      this.showConfirm(false)
      const res = await this.$store.dispatch('company/' + types.COMPANY_DEL, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.done = true
        this.message = '企業を削除しました。'
      }
      this.showMessage()
    }
  }
})

