import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import MemberMixin from '@/mixins/member'
import ComponentMixin from '@/mixins/components'
import FormMixin from '@/mixins/form'

export default Vue.extend({
  mixins: [
    MemberMixin,
    ComponentMixin,
    FormMixin,
  ],
  components: {
  },
  data() {
    return {
    }
  },
  watch: {
    $route (to, from) {
      console.log('router changed', to, from)
      console.log('current', this.$route.path)
      this.routeChanged()
    }
  },
  created () {
    console.log('MemberViewMixin.created')
    const chapterNo = parseInt(this.$route.params.chapter)
    this.$store.commit('common/' + types.CHAPTER_SET, chapterNo)
  },
  methods: {
    routeChanged() {
      console.log('routeChanged default')
    }
  }
})

