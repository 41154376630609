import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import CommonMixin from '@/mixins/common'

const sectionToPath = {
  [consts.SCT_SUMMARY]: 'learning/summary',
  [consts.SCT_VIDEO]: 'learning/video',
  [consts.SCT_POINT]: 'learning/point/1',
  [consts.SCT_QUIZ]:  'learning/quiz-top',
  [consts.SCT_QUIZ_EXP]: 'learning/quiz-exp',
  [consts.SCT_COMPLETE]: 'learning/summary',
}

const rePathA = new RegExp('(/m/chapter/\\d+/)(.+)/(\\d+)')
const rePathB = new RegExp('(/m/chapter/\\d+/)(.+)')
const transitions: string[] = [
  '/m/mypage',
  'learning/summary',
  'learning/video',
  'learning/point',
  'learning/quiz-top',
  'learning/quiz',
  'learning/quiz-exp',
  'achievement',
]

export default Vue.extend({
  mixins: [
    CommonMixin,
  ],
  components: {
  },
  data() {
    return {
    }
  },
  created () {
    //console.log('MemberMixin.created')
  },
  methods: {
    startChapter(chapterNo: number) {
      logger.debug('startChapter chapterNo=', chapterNo)
      this.$store.commit('member/' + types.CHAPTER_SET, chapterNo)
      const p = this.mstate.p.progress[chapterNo - 1]
      logger.debug('startChapter p=', p)
      let section = 'summary'
      if (p) {
        section = p.section
      }
      this.gotoSection(section, chapterNo)
    },
    getPathSfx(sctno, offset, sctmax) {
      let no = sctno + offset
      let sfx = ''
      if (no == -1) {
        no = sctmax
      }
      if (1 <= no && no <= sctmax) {
        sfx = `/${no}`
      }
      logger.debug('sfx=', sfx)
      return sfx
    },
    getTransition(offset: number) {
      const mA = rePathA.exec(this.$route.path)
      const mB = rePathB.exec(this.$route.path)
      let no = 0
      let path0 = ''
      let section = ''
      let t = null
      let tt = null
      if (mA) {
        const m = mA
        path0 = m[1]
        section = m[2]
        no = parseInt(m[3])
      } else if (mB) {
        const m = mB
        path0 = m[1]
        section = m[2]
      }
      const i = transitions.indexOf(section)
      if (i >= 0) {
        t = tt = transitions[i + offset]
      }
      if (no > 0) {
        t = section
      }

      let sfx = ''
      if (t == 'learning/point') {
        sfx = this.getPathSfx(no, offset, this.cstate.npoints)
        if (sfx == '') {
          t = tt
        }
      } else if (t == 'learning/quiz') {
        sfx = this.getPathSfx(no, offset, this.cstate.nquizes)
        if (sfx == '') {
          t = tt
        }
      } else if (t == '/m/mypage') {
        path0 = ''
      }

      logger.debug('t=', t + sfx)
      if (t) {
        return `${path0}${t + sfx}`
      } else {
        return null
      }
    },
    goNext() {
      logger.debug('goNext: currnt path=', this.$route.path)
      const path = this.getTransition(1)
      if (path) {
        this.$router.push(path)
      }
    },
    goPrev() {
      logger.debug('goPrev: currnt path=', this.$route.path)
      const path = this.getTransition(-1)
      if (path) {
        this.$router.push(path)
      }
    },
    gotoMypage() {
      logger.debug('gotoMypage')
      this.$router.push('/m/mypage', () => {
        //
      })
    },
    gotoSection(section, chno) {
      const chapter = chno || this.$route.params.chapter
      logger.debug('goto: chapter=', this.$route.params.chapter)
      logger.debug('goto: section=', section)
      const path = sectionToPath[section]
      logger.debug('goto: path=', path)
      if (chapter && path) {
        this.$router.push(`/m/chapter/${chapter}/${path}`)
      }
    },
    getSection() {
      const cpath  = this.$route.path
      const s2p = Object.entries(sectionToPath)
      for (const [section, path] of s2p) {
        if (section == consts.SCT_POINT) {
          path = 'learning/point'
        }
        if (cpath.indexOf(path) >= 0) {
          return section
        }
      }
      return null
    }
  }
})

