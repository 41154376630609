import { render, staticRenderFns } from "./C4dChapterTeamCard.vue?vue&type=template&id=58013f48&scoped=true&"
import script from "./C4dChapterTeamCard.vue?vue&type=script&lang=ts&"
export * from "./C4dChapterTeamCard.vue?vue&type=script&lang=ts&"
import style0 from "./C4dChapterTeamCard.vue?vue&type=style&index=0&id=58013f48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../Users/hashi/Nextcloud/vagrant/shared/cx4dx/client/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58013f48",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../Users/hashi/Nextcloud/vagrant/shared/cx4dx/client/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCol,VDialog,VDivider,VIcon,VList,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VRow,VSpacer,VToolbar,VToolbarTitle})
