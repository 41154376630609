






















































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import MemberViewMixin from '@/mixins/memberview'

export default Vue.extend({
  mixins: [
    MemberViewMixin,
  ],
  components: {
  },
  data() {
    return {
      isLast: false,
      curr: {}
    }
  },
  created () {
    logger.debug('Point.created')
    this.routeChanged()
  },
  methods: {
    routeChanged() {
      console.log('Point.routeChanged')
      const no = parseInt(this.$route.params.no)
      this.curr = this.cstate.chapter.lectures[no - 1]
      this.isLast = this.cstate.npoints == no
    },
    beforeNext() {
      //const no = parseInt(this.$route.params.no)
      //const np = this.cstate.npoints
      //logger.debug(`Point.beforeNext ${no}/${np} isLast:${this.isLast}`)
      if (this.isLast) {
        this.$store.dispatch('member/' + types.PROGRESS_POST, consts.SCT_POINT);
      }
    },
  }
})

