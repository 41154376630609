

































































































































































import Vue from 'vue'
import _ from 'lodash'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import MemberViewMixin from '@/mixins/memberview'

export default Vue.extend({
  mixins: [
    MemberViewMixin,
  ],
  components: {
  },
  data() {
    return {
      message: '',
      quizes: [],
      expOpen: false,
      expQuiz: null,
      expChoiceCls: [],
    }
  },
  created () {
    logger.debug('QuizExp.created')
    this.message = '全問正解ですね！すばらしい！'
    this.quizes = this.cstate.chapter.quizes.map((q, index) => {
      const qno = index + 1
      const answer = this.$store.getters['member/quizAnswers'](qno)
      const choices = answer.map((a) => {
        return q.choices[a- 1]
      })
      const choice = choices.join('<br>')
      const correct = _.isEqual(answer, q.correct)
      if (!correct) {
        this.message = '残念！正解を確認しましょう！'
      }
      logger.debug(`qno=${qno} answer=${JSON.stringify(answer)} correct=${JSON.stringify(q.correct)} ${correct}`)
      return { q, qno, answer, choice, correct }
    })
  },
  methods: {
    prev() {
      this.gotoSection(consts.SCT_QUIZ)
    },
    showExp(quiz) {
      logger.debug('showExp quiz=', quiz)
      this.expOpen = true
      this.expQuiz = quiz
      this.expChoiceCorrect = quiz.q.choices.map((c, index) => {
        return quiz.q.correct.indexOf(index + 1) >= 0
      })
      console.log(this.expChoiceCorrect)
    }
  }
})

