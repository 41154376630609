


























































































































































































import Vue from 'vue'
import _ from 'lodash'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import { VERSION } from '@/version'
import MemberViewMixin from '@/mixins/memberview'

export default Vue.extend({
  mixins: [
    MemberViewMixin,
  ],
  components: {
  },
  data() {
    return {
      chapterList: [],
      badgesH1: [],
      badgesH2: [],
      learningChapter: null,
      todays_cx: {
        title: 'CXデザインの役割は、 意図した体験を提供できるようにすること',
        text: '「また買おう」「もっと買いたい」「誰かに教えてあげたい」といった、<span class="c4d-primary">会社やブランドに対してお客様が感じている信頼や愛着のことを、顧客ロイヤリティ</span>といいます。顧客ロイヤリティが高まると、お客様の定着率が高まり、お客様一人当たりの収益が増加し、紹介や口コミによる新しいお客様の獲得数が増えます。<span class="c4d-primary">CXに取り組む一番の目的は、顧客ロイヤリティを高めるということです。</span>'
      },
      VERSION,
    }
  },
  async created () {
    logger.debug('m/Top.created')
    await this.$store.dispatch('member/' + types.PROGRESS_GET)

    const chno = this.mstate.p.currChapterNo
    logger.debug('currChapterNo=', chno)
    const chapterList = this.cstate.p.chapterList
    this.learningChapter = chapterList[chno - 1]
    logger.debug('learningChapter=', this.learningChapter)
    this.badgesH1 = chapterList.slice(0, 5).map(this.skillBadge)
    this.badgesH2 = chapterList.slice(5).map(this.skillBadge)
    let compChapters = this.mstate.p.progress.filter((p) => {
      return p.section == consts.SCT_COMPLETE
    })
    if (compChapters.length > 0) {
      const cch = _.sample(compChapters)
      const chno = cch.chapter
      const point = _.sample(chapterList[chno - 1].lectures)
      this.todays_cx.title = point.title
      this.todays_cx.text = point.text
    }

    if (consts.PROGRESS_FREE) {
      this.chapterList = this.cstate.p.chapterList
    } else {
      const progress = this.mstate.p.progress
      let lch = chno
      if (progress.length == 0) {
        lch = 1
      } else {
        const p = progress.slice(-1)[0]
        lch = p.chapter
        if (p.section == consts.SCT_COMPLETE) {
          lch++
        }
      }
      this.chapterList = this.cstate.p.chapterList.slice(0, lch)
    }
  },
  methods: {
    skillBadge(c) {
      let badge = 'badge_s_d.svg'
      let p = this.mstate.p.progress[c.chapter - 1]
      if (p && p.section == consts.SCT_COMPLETE) {
        badge = c.badge_s
      }
      return {
        chapter: c.chapter,
        skill: c.skill,
        badge,
      }
    }
  }
})

