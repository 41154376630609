



















































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  data() {
    return {
      new_group: false,
      btn: '保存',
      done: false,
      mode: '',
      confirm: '',
      message: null,
      form: {
        company_no: this.$route.params.companyNo,
        group_id: this.$route.params.groupId || 'new',
        name: '',
      }
    }
  },
  computed: {
    changed() {
      if (this.new_group) {
        return true
      }
      const group = this.gstate.group
      return this.form.name !== group.name
    }
  },
  created () {
    const companyNo = this.$route.params.companyNo
    const groupId = this.$route.params.groupId
    logger.debug('Company.created', companyNo)
    if (!groupId) {
      this.btn = '登録'
      this.new_group = true
      this.form.name = ''
    } else if (this.gstate.group) {
      this.form.name = this.gstate.group.name
    }
  },
  methods: {
    cancel() {
      this.showConfirm(false)
    },
    action(mode) {
      this.mode = mode
      this.confirm = null
      switch (this.mode) {
        case 'register':
          if (!this.$refs.form.validate()) {
            return
          }
          if (this.new_group) {
            this.confirm = 'グループを追加してよろしいでしょうか？'
          } else {
            this.doPost()
          }
          break
        case 'delete':
          this.confirm = 'グループ内の全利用者のアカウントを削除します。グループを削除してよろしいでしょうか？'
          break
      }
      this.showConfirm()
    },
    doAction() {
      switch (this.mode) {
        case 'register':
          this.doPost()
          break
        case 'delete':
          this.doDelete()
      }
    },
    register() {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.new_group) {
        this.action('register')
      } else {
        this.doPost()
      }
    },
    async doPost() {
      this.showConfirm(false)
      const res = await this.$store.dispatch('group/' + types.GROUP_POST, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        logger.debug('group=', this.gstate.group)
        this.done = true
        if (this.new_group) {
          this.form.group_id = this.gstate.group.id
          this.invite()
          return
        } else {
          this.$router.back()
          return
        }
      }
      this.showMessage()
    },
    confirmed() {
      logger.debug('confirmed')
      if (this.done) {
        logger.debug('done mode=', this.mode)
        const companyNo = this.$route.params.companyNo
        switch (this.mode) {
          case 'register':
            if (this.new_group) {
              this.$router.push(`/c/${companyNo}`)
            } else {
              this.$router.back()
            }
            break
          case 'delete':
            this.$router.push(`/c/${companyNo}`)
            break
        }
      } else {
        this.showMessage(false)
      }
    },
    async invite() {
      const c = this.kstate.company
      if (c.nusers_total >= c.max_users) {
        this.done = false
        this.message = 'アカウント数が超過しました。'
        this.showMessage()
        return
      }

      const params = {
        group_id: this.form.group_id,
        role: consts.ROLE_GROUP
      }
      const res = await this.$store.dispatch('common/' + types.SIGNUP_ISSUE, params)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.message = `グループURLを発行しました<br>（コピーしてご案内ください）<br>${res.data.url}`
      }
      this.showMessage()
    },
    async doDelete() {
      this.showConfirm(false)
      const res = await this.$store.dispatch('group/' + types.GROUP_DEL, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.done = true
        this.message = 'グループを削除しました。'
      }
      this.showMessage()
    },
  }
})

