
import VueRouter, { RouteConfig } from 'vue-router'
import Top from '@/views/a/Top.vue'

export const admin: Array<RouteConfig> = [
  {
    path: '',
    name: 'top',
    component: Top
  },
]

