














import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  components: {
  },
  data() {
    return {
      done: false,
      message: null,
      form: {
        code: this.$route.query.code
      }
    }
  },
  async created () {
    logger.debug('Update.created')
    this.showMessage_ = false
    const res = await this.$store.dispatch('member/' + types.MEMBER_VERIFY, this.form)
    this.message = this.cstate.error
    if (res.status == 200 && res.data.status === 0) {
      this.message = 'メールによる確認を完了しました。'
    }
    this.showMessage()
  },
  methods: {
    confirmed() {
      this.showMessage(false)
      this.$router.replace('/')
    }
  }
})

