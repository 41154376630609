
import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { api } from '@/api'

export const admin = {
  namespaced: true,
  state: {
    companyList: [],
    companyDict: {},
  },
  mutations: {
    [types.COMPANY_LIST] (state, value) {
      logger.debug('M admin/COMPANY_LIST')
      state.companyList = value
      const cd = {}
      state.companyList.forEach((c) => {
        cd[c.no] = c
      })
      state.companyDict = cd
    },
  },
  actions: {
    async [types.COMPANY_LIST] ({ state, commit, dispatch }, params) {
      logger.debug('A admin/COMPANY_LIST')
      const res = await api.get('/companies')
      if (res.status === 200) {
        commit(types.COMPANY_LIST, res.data.companies)
      }
      return res
    },
  }
}
