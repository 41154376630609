








































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminMixin from '@/mixins/admin'
import FormMixin from '@/mixins/form'

export default Vue.extend({
  mixins: [
    AdminMixin,
    FormMixin,
  ],
  components: {
  },
  props: {
    value: [String, File],
    initUrl: String,
  },
  data() {
    return {
      valid: false,
      image: null,
      url_: null,
    }
  },
  computed: {
    url() {
      return this.url_ || this.initUrl
    }
  },
  created() {
    logger.debug('C4dImageUploader.created')
  },
  methods: {
    fileSelected() {
      logger.debug('image=', this.image)
      if (this.image) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.url_ = e.target.result
        };
        reader.readAsDataURL(this.image)
      } else {
        this.url_ = null
      }
      this.$emit('input', this.image)
    }
  }
})

