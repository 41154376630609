





























































































import Vue from 'vue'
import _ from 'lodash'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    AdminViewMixin,
  ],
  components: {
  },
  data() {
    return {
      myself: false,
      done: false,
      show: false,
      mode: '',
      confirm: '',
      message: null,
      email_changed: false,
      orig: null,
      icon_url: null,
      form: {
        id: null,
        email: '',
        name: '',
        icon: null,
      }
    }
  },
  computed: {
    changed() {
      logger.debug('Settings.changed')
      if (!this.orig) {
        return false
      }
      const member = this.orig
      return this.form.email !== member.email ||
        this.form.name !== member.name ||
        this.form.icon
    }
  },
  async created () {
    logger.debug('Settings.created')
    const userId = this.$route.params.userId
    const cuser = this.cstate.p.user
    const res = await this.$store.dispatch('member/' + types.MEMBER_GET, userId || cuser.id)
    let orig
    if (userId) {
      orig = this.mstate.member
    } else {
      orig = this.cstate.p.user
    }
    if (orig) {
      this.orig = _.cloneDeep(orig)
      this.form.id = orig.id
      this.form.email = orig.email
      this.form.name = orig.name
      this.form.icon = null
      this.icon_url = orig.icon_url
      if (!this.icon_url) {
        this.icon_url = '/images/icon_account_me.svg'
      }
    }
    this.myself = this.form.id == this.cstate.p.user.id
    logger.debug('Settings.created myself=', this.myself)
  },
  methods: {
    cancel() {
      this.showConfirm(false)
    },
    action(mode) {
      this.mode = mode
      this.confirm = null
      switch (this.mode) {
        case 'register':
          this.register()
          break
        case 'delete':
          this.confirm = 'アカウントを削除しますか。'
          break
        case 'pwreset':
          this.doPwreset()
          break
      }
      if (this.confirm) {
        this.showConfirm()
      }
    },
    doAction() {
      switch (this.mode) {
        case 'delete':
          this.doDelete()
          break
      }
    },
    register() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.email_changed = this.form.email !== this.orig.email
      this.doPost()
    },
    async doPost() {
      const res = await this.$store.dispatch('member/' + types.MEMBER_POST, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.done = true
        if (this.email_changed) {
          this.message = '確認メールを送信しました。'
        } else {
          this.message = 'アカウント情報を登録しました。'
        }
      }
      this.showMessage()
    },
    confirmed() {
      logger.debug('confirmed')
      if (this.done) {
        logger.debug('done mode=', this.mode)
        switch (this.mode) {
          case 'register':
            this.$router.back()
            break
          case 'delete':
          case 'pwreset':
            if (this.myself) {
              this.$store.dispatch('common/' + types.LOGOUT)
            } else {
              this.$router.back()
            }
            break
        }
      } else {
        this.showMessage(false)
      }
    },
    async doDelete() {
      this.showConfirm(false)
      const res = await this.$store.dispatch('member/' + types.MEMBER_DEL, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.done = true
        this.message = 'アカウント情報を削除しました。'
      }
      this.showMessage()
    },
    async doPwreset() {
      const res = await this.$store.dispatch('common/' + types.PWRESET_S1, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.done = true
        this.message = 'パスワード再設定メールを送信しました。'
      }
      this.showMessage()
    }
  }
})

