


































































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import MemberViewMixin from '@/mixins/memberview'

export default Vue.extend({
  mixins: [
    MemberViewMixin,
  ],
  components: {
  },
  created () {
    logger.debug('Achievement.created')
  },
  mounted () {
    logger.debug('Video.mounted')
    this.$store.dispatch('member/' + types.PROGRESS_POST, consts.SCT_COMPLETE)
  },
})

