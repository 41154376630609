





















import Vue from 'vue';
import { api } from '@/api'
import axios from 'axios'
import { VERSION as version } from '@/version'
import CommonMixin from '@/mixins/common'

export default Vue.extend({
  mixins: [
    CommonMixin,
  ],
  data () {
    return {
      hasNewversion: false,
      versionServer: null,
      version
    }
  },
  async created () {
    const res = await api.get('/version')
    if (res.status === 200) {
      this.versionServer = res.data.replace(/\s+$/, '')
      this.hasNewversion = this.version !== this.versionServer
    }
  }
})
