import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import CommonMixin from '@/mixins/common'

export default Vue.extend({
  mixins: [
    CommonMixin,
  ],
  components: {
  },
  data() {
    return {
    }
  },
  created () {
    //
  },
  methods: {
  }
})

