




























































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import MemberViewMixin from '@/mixins/memberview'

export default Vue.extend({
  mixins: [
    MemberViewMixin,
  ],
  components: {
  },
  data() {
    return {
      message: null,
      show_pw: false,
      form: {
        email: '',
        password: '',
      }
    }
  },
  methods: {
    async login() {
      const res = await this.$store.dispatch('common/' + types.LOGIN, this.form)
      this.message = this.cstate.error
      if (res.status == 200 && res.data.status === 0) {
        this.$router.push('/')
      }
    }
  }
})

