









































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import AdminMixin from '@/mixins/admin'
import C4dBtn from '@/components/C4dBtn.vue'

export default Vue.extend({
  mixins: [
    AdminMixin,
  ],
  components: {
    C4dBtn,
  },
  props: {
    group: Object,
    edit: {
      type: Boolean,
      default: false
    },
    showProg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
    admin() {
      if (this.group.admin) {
        return this.group.admin.join(' ')
      }
      return ''
    }
  },
  created () {
    logger.debug('C4dGroupCard.created')
  },
  methods: {
    click() {
      const companyNo = this.$route.params.companyNo
      this.$store.commit('group/' + types.GROUP_SET, this.group)
      const path = `/g/${companyNo}/${this.group.id}`
      if (this.edit) {
        this.$router.push(path + '/edit')
      } else {
        this.$router.push(path)
      }
    }
  }
})

