















































































































import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import MemberViewMixin from '@/mixins/memberview'
import AdminViewMixin from '@/mixins/adminview'

export default Vue.extend({
  mixins: [
    MemberViewMixin,
    AdminViewMixin,
  ],
  components: {
  },
  data() {
    return {
      admin: false,
      ready: false,
      teamProgress: 0,
      userProgress: {},
      chapProgress: [],
    }
  },
  async created () {
    this.admin = this.isAdmin || this.isAdminCompany || this.isAdminGroup
    logger.debug('Team.create', this.admin)
    const params = {
      group_id: 'me'
    }
    const groupId = this.$route.params.groupId
    if (groupId) {
      params.group_id = groupId
    }
    const res = await this.$store.dispatch('group/' + types.PROGRESS_GET, params)
    if (res.status === 200) {
      let chapProgress = this.cstate.p.chapterList.map((c) => {
        return {
          c,
          complete: false,
          num: 0,
          progress: []
        }
      })
      const group = res.data.group
      group.progress.forEach((p) => {
        if (p.section == consts.SCT_COMPLETE) {
          const cp = chapProgress[p.chapter - 1]
          cp.progress.push(p)
        }
        if (p.user_id !== this.cstate.p.user.id) {
          //
        }
        let up = this.userProgress[p.user_id]
        if (!up) {
          up = 0
        }
        this.userProgress[p.user_id] = up + p.rate
      })
      const progress = Object.values(this.userProgress)
      let teamTotal = 0
      for(const p of progress) {
        teamTotal += p
      }
      if (group.num > 0) {
        this.teamProgress = Math.round(teamTotal / group.num)
      } else {
        this.teamProgress = 0
      }
      this.chapProgress = chapProgress.
        filter(p => p.progress.length > 0).
        map((p) => {
          p.num = p.progress.length
          p.complete = p.num === group.num
          p.progress = p.progress.map((pp) => {
            if (pp.user_id === this.cstate.p.user.id) {
              pp.updated_at = '2021-01-01T00:00:00'
            }
            return pp
          }).sort((a, b) => {
            const ta = new Date(a.updated_at)
            const tb = new Date(b.updated_at)
            return ta - tb
          })
          return p
        })
      logger.debug('userProgress=', this.userProgress)
      logger.debug('chapProgress=', this.chapProgress)
    }
    this.ready = true
  }
})

