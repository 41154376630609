
export const types = {
  LOADING: 'LOADING',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  ERROR: 'ERROR',
  TOKENN_REFRESH: 'TOKENN_REFRESH',
  ACCOUNT_GET: 'ACCOUNT_GET',
  SIGNUP_ISSUE: 'SIGNUP_ISSUE',
  SIGNUP_CHECK: 'SIGNUP_CHECK',
  SIGNUP_TEMP: 'SIGNUP_TEMP',
  SIGNUP_VERIFY: 'SIGNUP_VERIFY',
  PWRESET_S1: 'PWRESET_S1',
  PWRESET_S2: 'PWRESET_S2',
  CHAPTER_LIST: 'CHAPTER_LIST',
  CHAPTER_START: 'CHAPTER_START',
  CHAPTER_SET: 'CHAPTER_SET',
  PROGRESS_SET: 'PROGRESS_SET',
  PROGRESS_GET: 'PROGRESS_GET',
  PROGRESS_POST: 'PROGRESS_POST',
  PROGRESS_TOTAL: 'PROGRESS_TOTAL',
  COMPANY_LIST: 'COMPANY_LIST',
  COMPANY_GET: 'COMPANY_GET',
  COMPANY_SET: 'COMPANY_SET',
  COMPANY_POST: 'COMPANY_POST',
  COMPANY_DEL: 'COMPANY_DEL',
  GROUP_LIST: 'GROUP_LIST',
  GROUP_GET: 'GROUP_GET',
  GROUP_SET: 'GROUP_SET',
  GROUP_POST: 'GROUP_POST',
  GROUP_DEL: 'GROUP_DEL',
  MEMBER_LIST: 'MEMBER_LIST',
  MEMBER_GET: 'MEMBER_GET',
  MEMBER_SET: 'MEMBER_SET',
  MEMBER_POST: 'MEMBER_POST',
  MEMBER_DEL: 'MEMBER_DEL',
  MEMBER_VERIFY: 'MEMBER_VERIFY',
}

