























import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import MemberMixin from '@/mixins/member'

export default Vue.extend({
  mixins: [
    MemberMixin
  ],
  components: {
  },
  props: {
    user: Object,
    showName: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      isMe: false,
      name: '',
      url: ''
    }
  },
  created () {
    this.isMe = this.user.id === this.cstate.p.user.id
    if (this.isMe) {
      this.url = '/images/icon_account_me.svg'
      this.name = 'あなた'
    } else {
      this.url = '/images/icon_account_them.svg'
      this.name = this.user.name
    }
    if (this.user.icon_url) {
      this.url = this.user.icon_url
    }
    if (this.name.length > 10) {
      this.name = this.name.slice(0, 9) + '…'
    }
  }
})

