import Vue from 'vue'

import { logger } from '@/logger'
import { types } from '@/store/mutation-types'
import { consts } from '@/consts'
import AdminMixin from '@/mixins/admin'
import ComponentMixin from '@/mixins/components'
import FormMixin from '@/mixins/form'

export default Vue.extend({
  mixins: [
    AdminMixin,
    ComponentMixin,
    FormMixin,
  ],
  components: {
  },
  data() {
    return {
      isAdmin: false,
      isAdminCompany: false,
      isAdminGroup: false,
      isUser: false,
      showMessage_: false,
      showConfirm_: false,
    }
  },
  created () {
    logger.debug('AdminViewMixin.created')
    const user = this.cstate.p.user
    if (user) {
      const role = user.role
      this.isAdmin = role === consts.ROLE_ADMIN
      this.isAdminCompany = role === consts.ROLE_COMPANY
      this.isAdminGroup = role === consts.ROLE_GROUP
      this.isUser = role === consts.ROLE_USER
    }
  },
  methods: {
    showMessage(flag) {
      this.showMessage_ = typeof flag === 'undefined' ? true : flag
    },
    showConfirm(flag) {
      this.showConfirm_ = typeof flag === 'undefined' ? true : flag
    }
  }
})

